import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AppInfoService {
  constructor() {}

  public get title() {
    if (environment.isUsa) {
      return "P3-BPG USA";
    } else return "P3-GGP";
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}
