import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { P3AuthService, ScreenService } from "../../services";
import { UserPanelModule } from "../user-panel/user-panel.component";
import { DxButtonModule } from "devextreme-angular/ui/button";
import { DxToolbarModule } from "devextreme-angular/ui/toolbar";

import { Router } from "@angular/router";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import { formatMessage } from "devextreme/localization";
import { User } from "../../model/user/user";
import { Observable } from "rxjs";
import { DxSelectBoxModule } from "devextreme-angular";
import { LocaleService } from "../../services/locale.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  @Input()
  isLoggedIn: boolean = false;

  formatMessage = formatMessage;

  currentUser$: Observable<User>;
  currentLocale: string | null;

  isXSmall: boolean;
  isUsa = environment.isUsa;

  userMenuItems = [
    {
      text: "Profil",
      icon: "user",
      onClick: () => {
        this.router.navigate(["/profile"]);
        this.treeViewInstanceService.getTreeViewInstance().unselectAll();
      },
    },
    {
      text: "Ausloggen",
      icon: "dx-icon material-icons mdi-logout",
      onClick: () => {
        this.authService.logOut();
      },
    },
  ];

  constructor(
    private authService: P3AuthService,
    private localeService: LocaleService,
    private router: Router,
    private treeViewInstanceService: TreeViewInstanceService,
    private screen: ScreenService
  ) {}

  ngOnInit() {
    if (this.isLoggedIn) {
      this.currentUser$ = this.authService.getUser();

      this.localeService
        .getCurrentLocale()
        .subscribe((locale) => (this.currentLocale = locale));
    } else {
      this.currentLocale = this.localeService.getCurrentLocaleWithoutUser();
    }

    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  logOut(): void {
    this.authService.logOut();
  }

  toggleLocale() {
    if (this.currentLocale === "de-DE") {
      localStorage.setItem("locale", "en-EN");
    } else {
      localStorage.setItem("locale", "de-DE");
    }

    window.location.reload();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxSelectBoxModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
